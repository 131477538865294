(function() {
  var config = angular.module('priorConfig', []);

  config.constant('apiEndpoint', 'https://viro-api-dev.priorhq.com');
  config.constant('appName', 'BENDITO | TAKE•AWAY Caído del cielo');
  config.constant('brandId', 1);
  config.constant('isDev', true);
  config.constant('apiVersion', 2);
  config.constant('facebookAppId', '849612648881441');
  config.constant('facebookPixelId', '');
  config.constant('gtagPropertyIds', ['UA-58233046-6']);
  config.constant('gtmContainerIds', []);
  config.constant('trackGtagConversions', []);
  config.constant('trackGATransactions', ['UA-58233046-6']);
  config.constant('trackGAViews', ['UA-58233046-6']);
  config.constant('currencyMultiplier', 100);
  config.constant('currencyFractionDigits', 2);
  config.constant('kushkiClientId', 'de015dde66054e41a5f66e519f4e2588');
  config.constant('kushkiTestEnvironment', true);
  config.constant('mangopayClientId', 'tgo-mangopay');
  config.constant('mangopayBaseUrl', 'https://api.sandbox.mangopay.com');
  config.constant('defaultLanguage', 'es-ES');
  config.constant('gaUseGtag', true);
})();
